"use strict";

export function initInScope($scope) {
    $scope.find('.js-download-product__button').on('click', function(evt) {
        let link = $(this).data('download-link');
        let modal = $('.js-download-product-overlay');
        let form = modal.find('.js-download-product-overlay__form')

        form.attr('action', link);

        form.on('submit', function(evt) {
            modal.modal('hide');
        });

        modal.on('hidden.bs.modal', function(){
            form[0].reset();
            form.parsley().reset();
        });

    });
}