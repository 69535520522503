"use strict";

export function initInScope($scope) {
    let $container = $scope.find('.js-scale-teaser');

    $container.find('.js-scale-teaser__item').on('mouseover click', function (evt) {
        // console.log(evt.key)
        // console.log(evt.keyCode)
        // if(evt.keyCode !== 13 && evt.type === 'keydown'){
        //     return;
        // }
        let $this = $(this);

        if (!$this.hasClass('is-active')) {
            let currentIndex = $this.index();

            $container.find('.js-scale-teaser__item').each(function(){
                $(this).removeClass('is-active').removeClass('is-before');
            });

            $this.addClass('is-active');

            for (let i = 1; i <= currentIndex; i++) {
                $container.find('.js-scale-teaser__item:nth-child(' + i + ')').addClass('is-before');
            }
        }
    });
}