export function setup() {
    window.dataLayer = window['dataLayer'] || [];
}

export function trackEvent({category, action, label, value}, {debug}) {
    let dataLayerData = {
        'event': 'genericGtmEvent',
        'genericEventCategory': category,
        ...(exists(action) ? {'genericEventAction': action}: null),
        ...(exists(label) ? {'genericEventLabel': label}: null),
        ...(exists(value) ? {'genericEventValue': value}: null),
    };

    window.dataLayer.push(dataLayerData);

    if (debug) {
        console.log('GTM Tracking - dataLayer push:', dataLayerData);
    }
}

function exists(x) {
    return x !== null && typeof x !== 'undefined'
}