"use strict";

export function initInScope ($scope) {
    $scope.find('.js-countdown').each(function () {
        let $this = $(this),
            countdownTimestamp = $this.data('time');

        countdown($this, countdownTimestamp);
    });

    function countdown($this, countdownTimestamp){
        let interval = setInterval(function(){
            let timestamp = countdownTimestamp - (new Date()).getTime();

            // let days    = component(timestamp, aDay),
            let days = Math.floor(timestamp / (1000 * 60 * 60 * 24)),
                hours   = Math.floor((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60)),
                seconds = Math.floor((timestamp % (1000 * 60)) / 1000);

            if(timestamp < 0) {
                clearInterval(interval);
                $this.find('.js-countdown__item').html('00');
            } else {
                $this.find('.js-countdown__item[data-type="days"]').html(("0" + days).slice(-2));
                $this.find('.js-countdown__item[data-type="hours"]').html(("0" + hours).slice(-2));
                $this.find('.js-countdown__item[data-type="minutes"]').html(("0" + minutes).slice(-2));
                $this.find('.js-countdown__item[data-type="seconds"]').html(("0" + seconds).slice(-2));
            }
        }, 1000);
    }
}