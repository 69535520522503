"use strict";

export function initInScope($scope) {
    $scope.find('.js-required-group').on('change', function () {
        let $this = $(this);

        if ($this.find('.js-required-group__input:checked').length > 0) {
            $this.find('.js-required-group__input:not(:checked)').prop('required', false);
        } else {
            $this.find('.js-required-group__input').prop('required', true);
        }

        $this.closest('form').parsley().validate();
    });
}