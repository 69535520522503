"use strict";
import fetch from '@elements/fetch';
import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope ($scope) {
    let $video = $scope.find($('.js-fetch-video'));
    let $target = $video.find($('.js-fetch-video__target'));

    $video.find('.js-fetch-video__play').on('click', function (evt) {

            let pendingRequest = fetch($target.data('fetch-video'));

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                if(res.success){
                    $target.prepend(res.html);
                    initModulesInScope($target);
                }
            }).catch((error) => {
                if(error.name !== 'AbortError'){
                    console.log(error);
                }
            });
    });

}
