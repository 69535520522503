import $ from 'jquery';

// import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/video';
app.modules.videoJs = videoJs.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as slider from '@elements/slider';
app.modules.slider = slider.createInitInScope('.js-slider', {
    dots: true, /*should always be true; hide with css (accessibility)*/
    dotsClass: 'sr-only',
    swipeToSlide: true,
    infinite: false,
    prevArrow: '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    nextArrow: '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        }
    ]
});

import * as collapseMore from './scripts/collapse-more';
app.modules.collapseMore = collapseMore.initInScope;

app.modules.heroSlider = slider.createInitInScope('.js-hero-slider', {
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev slider__arrow slider__arrow--transparent" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    nextArrow: '<button type="button" class="slick-next slider__arrow slider__arrow--transparent" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    dotsClass: 'sr-only'
});

app.modules.teaserSlider = slider.createInitInScope('.js-teaser-slider', {
    dots: true, /*should always be true; hide with css (accessibility)*/
    dotsClass: 'sr-only',
    slidesToShow: 3,
    swipeToSlide: true,
    infinite: false,
    prevArrow: '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    nextArrow: '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.06,
                centerMode: true,
                centerPadding: '16px',
                arrows: false
            }
        }
    ]
});

import * as sliderWithThumbs from './scripts/slider-with-thumbs';
app.modules.sliderWithThumbs = sliderWithThumbs.initInScope;

if (matchMedia('(max-width: 767px)').matches) {
    app.modules.teaserSliderXs = slider.createInitInScope('.js-teaser-slider-xs', {
        dots: true, /*should always be true; hide with css (accessibility)*/
        dotsClass: 'sr-only',
        slidesToShow: 1.1,
        swipeToSlide: true,
        infinite: false,
        slidesToScroll: 1,
        speed: 200,
        arrows: false
    });
}

import * as imgTextSlider from './scripts/img-text-slider';
app.modules.imgTextSlider = imgTextSlider.initInScope;

app.modules.textSlider = slider.createInitInScope('.js-text-slider', {
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev slider__arrow slider__arrow--transparent sr-only" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    nextArrow: '<button type="button" class="slick-next slider__arrow slider__arrow--transparent sr-only" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    fade: true,
    speed: 300,
    autoplaySpeed: 4000,
    autoplay: true,
    pauseOnHover: false
});

app.modules.logoSlider = slider.createInitInScope('.js-logo-slider', {
    dots: true, /*should always be true; hide with css (accessibility)*/
    dotsClass: 'sr-only',
    slidesToShow: 6,
    infinite: false,
    prevArrow: '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    nextArrow: '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2.5
            }
        }
    ]
});

import * as sliderControls from './scripts/slider-controls';
app.modules.sliderControls = sliderControls.initInScope;

import * as sliderCount from './scripts/slider-counter';
app.modules.sliderCount = sliderCount.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as navToggle from './scripts/nav-toggle';
app.navToggle = navToggle.init;

import * as toggleNavLevel from './scripts/toggle-navlevel';
app.modules.toggleNavLevel = toggleNavLevel.initInScope;

import * as nav from '@elements/accessibility-nav';
app.modules.accessibilityNav = nav.createInitInScope('.js-touch-nav', '.js-touch-nav__item');

import * as touchNav from '@elements/touch-nav';
app.modules.touchNav = touchNav.initInScope;

import * as affixNavbar from './scripts/affix-navbar';
// if (matchMedia('(min-width: 768px)').matches) {
    app.affixNavbar = affixNavbar.init;
// }

import * as toggleOverlay from './scripts/toggle-overlay';
app.modules.toggleOverlay = toggleOverlay.initInScope;
console.log('script')

import * as downloadModal from './scripts/download-modal';
app.modules.downloadModal = downloadModal.initInScope;

import * as search from './scripts/search';
app.modules.search = search.initInScope;

import * as fileInput from '@elements/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as fileInputValidation from './scripts/file-input-validation';
app.modules.fileInputValidation = fileInputValidation.initInScope;

import * as fileInputSize from './scripts/file-input-size';
app.modules.fileInputSize = fileInputSize.initInScope;

import * as recaptcha from './scripts/recaptcha';
app.modules.recaptcha = recaptcha.initInScope;

import * as ajaxForm from './scripts/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    addUrlParams: true
});

import * as ajaxFormExtend from './scripts/ajax-form-extend'
app.modules.ajaxFormExtend = ajaxFormExtend.initInScope;

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as fadeIn from './scripts/fade-in';
app.modules.fadeIn = fadeIn.initInScope;

import * as transformIn from './scripts/transform-in';
app.modules.transformIn = transformIn.initInScope;

import * as smoothScroll from './scripts/smooth-scroll';
app.modules.smoothScroll = smoothScroll.initInScope;

import * as downloadProduct from './scripts/download-product';
app.modules.downloadProduct = downloadProduct.initInScope;

// import { showCookieBar } from "./scripts/cookie-permissions";
// if (!_config.admin && !_config.editmode) {
//     showCookieBar();
// }
//removed because of Onetrust

import * as affix from '@elements/affix';
app.modules.affix = affix.initInScope;


import * as leafletMap from './scripts/leaflet-map';
app.modules.leafletMap = leafletMap.initInScope;

import * as juicer from './scripts/juicer';
app.modules.juicer = juicer.initInScope;

import * as modalTemplate from './scripts/modal-template';
app.modules.modalTemplate = modalTemplate.initInScope;

import * as countdown from './scripts/countdown';
app.modules.countdown = countdown.initInScope;

import * as contentConnector from './scripts/content-connector';
app.modules.contentConnector = contentConnector.initInScope;

import * as requiredToggleGroup from './scripts/required-toggle-group';
app.modules.requiredToggleGroup = requiredToggleGroup.initInScope;

import * as scrollingTabs from './scripts/scrolling-tabs';
app.modules.scrollingTabs = scrollingTabs.initInScope;

import * as newsletterUnsubscribe from './scripts/newsletter-unsubscribe';
app.newsletterUnsubscribe = newsletterUnsubscribe.init;

import * as fetchTabs from './scripts/fetch-tabs';
app.modules.fetchTabs = fetchTabs.initInScope;

import * as fetchVideo from './scripts/fetch-video';
app.modules.fetchVideo = fetchVideo.initInScope;

import * as scaleTeaser from './scripts/scale-teaser';
app.modules.scaleTeaser = scaleTeaser.initInScope;

import * as renderTemplate from './scripts/render-template';
app.modules.renderTemplate = renderTemplate.initInScope;

import * as multiselectDropdown from './scripts/multiselect-dropdown';
app.multiselectDropdown = multiselectDropdown.init;

import * as urlScrollTo from './scripts/url-scroll-to';
app.urlScrollTo = urlScrollTo.init;

import * as consentOverlay from './scripts/consent-overlay';
app.modules.consentOverlay = consentOverlay.initInScope;


import * as tracking from "./scripts/tracking";
let options = {
    debug : true,
}; // optional options

tracking.initJentisEventTracking(options);
tracking.initGtmEventTracking(options);

import * as jentisForm from './scripts/tracking-src/jentis-form';
app.modules.jentisForm = jentisForm.initInScope;

import * as jentisShariff from './scripts/tracking-src/jentis-shariff-tracking';
app.modules.jentisShariff = jentisShariff.initInScope;

import * as jentisPageLoad from './scripts/tracking-src/jentis-page-load';
app.modules.jentisPageLoad = jentisPageLoad.initInScope;

import * as jentisScroll from './scripts/tracking-src/jentis-scroll';
jentisScroll.init();

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);
