"use strict";

import {initVideoTracking} from './video-js-tracking';

let currentId = 1;
export function initInScope ($scope) {
    let $videos = $scope.find('.js-video');
    console.log($videos);
    let defaultOptions = {};

        $videos.each(function () {
            let id = $(this).prop('id'),
                options = $.extend({}, defaultOptions);

            if (!id) {
                id = 'video-js-id-' + currentId++;
                $(this).prop('id', id);
            }

            let video = $(this);

            let $container = video.closest('.js-video-container');
            let $playButton = $container.find('.js-video__play');
            let $videoPoster = $container.find('.js-video__poster');

            if(video.hasClass('js-video--intro')){
                video[0].play();
            }

            video.on('playing', function () {
                if (video.hasClass('js-video--intro')) {
                    $container.addClass('is-playing--intro');
                }
                else{
                    video.attr('controls', true);
                    $container.addClass('is-playing');
                    $container.removeClass('is-ended');
                }
            });


            video.on('pause', function () {
                video.attr('controls', false);
                $container.removeClass('is-playing');
            });

            video.on('ended', function () {
                $container.removeClass('is-playing');

                if (video.hasClass('js-video--intro')) {
                    $container.removeClass('is-playing--intro');
                    $container.addClass('is-ended--intro');
                }
                else{
                    $container.addClass('is-ended');
                }
            });

            $playButton.on('click', function(){
                playVideo($container, video);
            });
            $videoPoster.on('click', function(){
                playVideo($container, video);
            });

            //video tracking
            if (video.hasClass('js-video-tracking')) {
                initVideoTracking(video);
            }
        });
}
function playVideo ($container, video){
    $container.addClass('is-playing');
    if (video.hasClass('js-video--intro')) {
        if($container.hasClass('is-playing--intro')){
            $container.removeClass('is-playing--intro');
            $container.addClass('is-ended--intro');
            $container.addClass('is-playing');
        }
    }
    else{
        video[0].play();
        video.attr('controls', true);
        $container.removeClass('is-playing--intro');
        $container.addClass('is-ended--intro');
        $container.addClass('is-playing');
    }
}