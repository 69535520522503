"use strict";

export function init() {
    let $container = $('.js-newsletter-unsubscribe');
    let $checkboxes = $container.find('.js-newsletter-unsubscribe__checkbox');
    let $tk = $container.find('.js-newsletter-unsubscribe__tk');
    let oldTk = $tk.html();
    let newTk = $tk.data('newsletter-unsubscribe');
    let unsubscribe = $container.data('newsletter-unsubscribe-enable');

    if(unsubscribe){
        if ($('.js-newsletter-unsubscribe__checkbox:checked').length === 0) {
            $tk.html(newTk);
        }else{
            $tk.html(oldTk);
        }

        $checkboxes.on('change', function() {
            if ($('.js-newsletter-unsubscribe__checkbox:checked').length === 0) {
                $tk.html(newTk);
            }else{
                $tk.html(oldTk);
            }
        });
    }else{
        if ($('.js-newsletter-unsubscribe__checkbox:checked').length > 0) {
            $('.js-newsletter-unsubscribe__checkbox:not(:checked)').prop('required', false);
        } else {
            $('.js-newsletter-unsubscribe__checkbox').prop('required', true);
        }

        $checkboxes.on('change', function() {
            if ($('.js-newsletter-unsubscribe__checkbox:checked').length > 0) {
                $('.js-newsletter-unsubscribe__checkbox:not(:checked)').prop('required', false);
            } else {
                $('.js-newsletter-unsubscribe__checkbox').prop('required', true);
            }
            $(this).closest('form').parsley().validate();
        });
    }
}