export function setup() {
    window._jts = window._jts || [];
}

export function trackEvent({category, action, label, value}, {debug}) {
    let dataLayerData = {
        "event-name": "genericGtmEvent",
        'genericEventCategory': category,
        ...(exists(action) ? {'genericEventAction': action}: null),
        ...(exists(label) ? {'genericEventLabel': label}: null),
        ...(exists(value) ? {'genericEventValue': value}: null),
        track: 'event' 
    };

    _jts.push(dataLayerData);

    if (debug) {
        console.log('Jentis Tracking - dataLayer push:', dataLayerData);
    }
}

function exists(x) {
    return x !== null && typeof x !== 'undefined'
}