"use strict";

export function init() {
    $('.js-toggle-nav').on('click', function (evt) {
       evt.preventDefault();

       let $mainNav = $(this).closest('.navbar');

       if(!$mainNav.hasClass('is-open')) {
           $mainNav.addClass('is-open');

           //close search
           let search = $mainNav.find('.navbar__main__top__search');
           if(search.hasClass('is-active')) {
               search.removeClass('is-active');
               $(search.data('target')).removeClass('is-open');
           }

           $('body').on('click', bodyClickHandler);
       } else {
           $mainNav.removeClass('is-open');

           $('body').off('click', bodyClickHandler);
       }
    });
}

function bodyClickHandler (evt) {
    if ($(evt.target).parents('.navbar').length < 1) {
        evt.preventDefault();
        $('.navbar').removeClass('is-open');
        $('body').off('click', bodyClickHandler);
    }
}