"use strict";

export function initInScope ($scope) {
    $scope.find('.js-slider-pause').click(function(evt) {
        evt.preventDefault();

        let $slider = $($(this).data('slider'));

        if(!$(this).hasClass('is-paused')) {
            $(this).addClass('is-paused');
            $slider.slick('slickPause');
        } else {
            $(this).removeClass('is-paused');
            $slider.slick('slickPlay');
        }
    });
}
