"use strict";

export function initInScope ($scope) {
    $scope.find('.js-collapse-more').on('shown.bs.collapse', function () {
        let $this = $(this);
        import('slick-carousel').then(function () {
            $this.find('.js-slider').each(function(){
                $(this).slick('setPosition');
            });
        });
    });
}