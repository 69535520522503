export function setup() {
    window._jts = window._jts || [];
}

export function initInScope($scope) {
    // Wait for the document to be ready
    $(document).ready(function () {
        if(!window.dataLayerPushed){
            let trackingScript = $('script:contains("_trackingData")');
            if (trackingScript.length > 0) {
                let label = _trackingData.search.datalayer.label,
                    action = _trackingData.search.datalayer.action,
                    category = _trackingData.search.datalayer.category;
                let dataLayerData = {
                    'track': 'event',
                    'event-name': 'genericGtmEvent',
                    'genericEventLabel': label,
                    'genericEventAction': action,
                    'genericEventCategory': category
                };

                _jts.push(dataLayerData);
                // Set a flag to prevent multiple pushes
                window.dataLayerPushed = true;
                console.log('Jentis Tracking Form - dataLayer push:', dataLayerData);
            }
        }

    });

}