// Version 1.6.0
// extend event fetched.ajax-form

import {isParsleyForm, isValid} from "@elements/parsley-bootstrap-validation";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import $ from "jquery";
import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from '@elements/fetch'; // IE10 Polyfill
import { debounce } from "debounce";
import asyncAppend from '@elements/async-append';
import formDataEntries from 'form-data-entries';
import mapObject from "object.map";

const defaultSelectors = {
    base: '.js-ajax-form',
    result: '.js-ajax-form__result',
    loading: '.js-ajax-form__loading',
    notifications: '.js-ajax-form__notifications',
    form: '.js-ajax-form__form',
    additionalForm: '.js-ajax-form__additional-form',
    errorArea: '.js-ajax-form__error-area',
    retry: '.js-ajax-form__retry',
    link: '.js-ajax-form__link'
};

const defaultOptions = {
    submitOnChange: false,
    addUrlParams: false,
    fetchHeaders: {}
};

export function createInitInScope(options = defaultOptions, selectors = defaultSelectors) {
    return function ($scope) {
        return getElementBySelector(selectors.base, $scope).each(function () {
            createAjaxForm($(this), {...defaultSelectors, ...selectors}, options);
        });
    }
}

export function createAjaxForm($baseElement, selectors = defaultSelectors, options = defaultOptions) {
    let $elements = getElementsBySelectorObject({...defaultSelectors, ...selectors}, $baseElement);

    let pendingRequest = null;

    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('ajax-form', $baseElement)
    };


    addSubmitHandler();

    function addSubmitHandler($form = $elements.form){
        $form.on('submit', (evt) => {
            evt.preventDefault();
            evt.stopImmediatePropagation(); // otherwise .on('submit.ajax-form') would be called twice
            submit();
        });

        if (options.submitOnChange) {
            $form.on('change', debounce(submit, 200));
        }
    }

    $elements.additionalForm.on('submit', (evt) => {
        evt.preventDefault();
        submit();
    });

    $elements.additionalForm.each((index, form) => {
        if (options.submitOnChange || $(form).data('ajax-form-submit-on-change')) {
            $(form).on('change', debounce(submit, 200));
        }
    });

    $elements.retry.on('click', function (evt) {
        evt.preventDefault();

        if (lastLoadParams) {
            load(...lastLoadParams);
        }
    });

    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));

    function addLinkClickHandler($links) {
        $links.on('click', function (evt) {
            evt.preventDefault();

            let href = $(this).attr('href') || $(this).data('href');
            let action = $elements.form.data('action') || $elements.form.attr('action');
            let params = new URL(href, location.origin).searchParams;

            pendingRequest = load(action, 'GET', params, href);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if ((!error || error.name !== 'AbortError') // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });
        });
    }

    let lastLoadParams = null; // for retry
    function load(url, method = "GET", params, historyUrl) {
        lastLoadParams = [url, method, params, historyUrl];
        $baseElement.trigger('fetch.ajax-form');

        if (options.addUrlParams) {
            history.replaceState(history.state, document.title, historyUrl || url);
        }

        if (method.toUpperCase() === "GET") {
            params.append('ajax', 1);
            // Add ajax param to differentiate between and ajax requests and page request.
            // Otherwise Chrome caches these results as normal pages and returns them from cache if the back button is pressed
            url = addSearchParamsToUrl(url, params);
        }

        let request = fetch(url, {
            method: method,
            headers: {
                'pragma' : 'no-cache',
                'cache-control': 'no-cache',
                ...options.fetchHeaders
            },
            ...(method.toUpperCase() !== "GET" ? {
                body: new URLSearchParams(params)
            } : {})
        });


        let $targetsByResultId = {};
        $elements.result.toArray().forEach((element) => {
            let resultId = $(element).data('result-id') || 'default';
            if ($targetsByResultId[resultId]) {
                $targetsByResultId[resultId] = $targetsByResultId[resultId].add($(element));
            } else {
                $targetsByResultId[resultId] = $(element);
            }
        });

        asyncAppend(
            {
                $target: $targetsByResultId,
                $loading: $elements.loading,
                $notifications: $elements.notifications
            },
            request
        )
            .then((result) => {
                let content = result.html || result.content;
                if (content && result.success !== false) {
                    $baseElement.trigger('success.ajax-form');
                    $elements.errorArea.attr('hidden', 'hidden');
                    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));

                    let $newForm = getElementBySelector(selectors.form, $baseElement);
                    if ($newForm && $newForm.length && $newForm[0] !== $elements.form[0]) {
                        $elements.form = $newForm;
                        addSubmitHandler($elements.form);
                    }
                } else {
                    $baseElement.trigger('failed.ajax-form');
                    $elements.errorArea.attr('hidden', null);
                }
                // console.log(result);

                $baseElement[0].dispatchEvent(new CustomEvent('fetched.ajax-form', {'detail': result}));
                // $baseElement.trigger('fetched.ajax-form', [result]);
            })
            .catch(() => {});

        // Unpack json response body if the promise was created via fetch
        // Otherwise the HTTP-Server error is not caught.
        // The fetch promise itself resolves (even with a http error)
        request.then(response => (response
            && response.json
            && typeof response.json === 'function'
            && response.clone
            && typeof response.clone === 'function')
            ? response.clone().json()
            : response
        ).catch((error, requestState) => {
            if ((!error || error.name !== 'AbortError') // native fetch abort
                && requestState !== 'abort') { // jquery abort
                console.error(error);
                $baseElement.trigger('failed.ajax-form');
                $elements.errorArea.attr('hidden', null);
            }
        });

        return request;
    }

    function submit() {
        // create promise to resolve/reject in right order (important for loading-indicator with multiple submissions)
        let readyToSubmit = new Promise(function (resolve, reject) {
            if (pendingRequest && pendingRequest.abort) {
                pendingRequest.abort();
                pendingRequest.catch(resolve);
                pendingRequest = null;
            } else {
                resolve();
            }
        });

        readyToSubmit.then(function () {
            if (isParsleyForm($elements.form) && !isValid($elements.form)) {
                return;
            }

            $baseElement.trigger('submit.ajax-form');

            let action = $elements.form.data('action') || $elements.form.attr('action');
            let method = $elements.form.data('method') || $elements.form.attr('method');
            let formDataEntries = getFormDataEntries();
            let params = new URLSearchParams(formDataEntries);

            call(options.onSubmit, {
                $element: $baseElement,
                $elements,
                options,
                formData: getFormData()
            });

            let url = new URL(location.href);
            url.searchParams.delete('page');
            url = addSearchParamsToUrl(url, params);

            pendingRequest = load(action, method, params, url);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if ((!error || error.name !== 'AbortError') // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });
        });
    }

    function getFormData() {
        return createFormData([$elements.form[0], ...$elements.additionalForm.toArray()]);
    }

    function getFormDataEntries () {
        return createFormDataEntries([$elements.form[0], ...$elements.additionalForm.toArray()]);
    }

    let api = {
        submit,
        getFormData,
        getFormDataEntries // todo add to doku
    };

    $baseElement.data('ajax-form', api);

    return api;
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}

export const initInScope = createInitInScope();

function createFormData(forms) {
    let formData = new FormData();
    forms.map(form => {
        for(var pair of formDataEntries(form)) {
            formData.append(...pair);
        }
    });

    return formData;
}

function createFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(var pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}


function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}

// function serialize(form, options) {
//     let defaults = {
//         include: [],
//         exclude: []
//     };
//
//     let config = Object.assign({}, defaults, options);
//     let data = {};
//
//     for (let element of form.elements) {
//         let tag = element.tagName;
//         let type = element.type;
//         if (tag === 'INPUT' && (type === 'password' || type === 'file')) {
//             continue // do not serialize passwords or files
//         }
//         if (isNameFiltered(element.name, config.include, config.exclude)) {
//             continue
//         }
//         if (tag === 'INPUT') {
//             let type = element.type;
//             if (type === 'radio') {
//                 if (element.checked) {
//                     pushToArray(data, element.name, element.value)
//                 }
//             } else if (type === 'checkbox') {
//                 pushToArray(data, element.name, element.checked)
//             } else {
//                 pushToArray(data, element.name, element.value)
//             }
//         } else if (tag === 'TEXTAREA') {
//             pushToArray(data, element.name, element.value)
//         } else if (tag === 'SELECT') {
//             if (element.multiple) {
//                 for (let option of element.options) {
//                     if (option.selected) {
//                         pushToArray(data, element.name, option.value)
//                     }
//                 }
//             } else {
//                 pushToArray(data, element.name, element.value)
//             }
//         }
//     }
//     return data
// }
//
// function deserialize(form, data, options) {
//     let defaults = {
//         valueFunctions: null,
//         include: [],
//         exclude: []
//     };
//
//     let config = Object.assign({}, defaults, options);
//
//     // apply given value functions first
//     let speciallyHandled = [];
//     if (config.valueFunctions !== null) {
//         speciallyHandled = applySpecialHandlers(data, form, config)
//     }
//     // fill remaining values normally
//     for (let name in data) {
//         if (isNameFiltered(name, config.include, config.exclude)) {
//             continue;
//         }
//
//         if (!speciallyHandled.includes(name)) {
//             let inputs = [...form.elements].filter(elem => elem.name === name);
//             inputs.forEach((input, i) => {
//                 applyValues(input, data[name], i)
//             })
//         }
//     }
// }
//
// function isNameFiltered(name, include, exclude) {
//     if (!name) {
//         return true
//     }
//
//     if (exclude.includes(name)) {
//         return true
//     }
//
//     return include.length > 0 && !include.includes(name);
// }
//
// function pushToArray(dict, key, value) {
//     if (!(key in dict)) {
//         dict[key] = []
//     }
//     dict[key].push(value)
// }


export function normalizeSelector(selector) {
    switch (typeof selector) {
        case 'string':
            return $container => $container.find(selector)
                .add($container.is(selector) ? $container: null);
        case 'function':
            return selector;
        default:
            return (selector instanceof $)
                ? () => selector
                : () => $(selector);
    }
}

export function getElementBySelector(selector, $container) {
    return normalizeSelector(selector)($container)
}

export function getElementsBySelectorObject(selectorObject, $container) {
    return mapObject(selectorObject, selector => getElementBySelector(selector, $container));
}