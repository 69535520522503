"use strict";

export function initInScope ($scope) {
    let $tab = $scope.find('.js-scrolling-tabs__item');

    $tab.each(function () {
        let $this = $(this);

        if ($this.hasClass('active')) {
            calcCenter($this.closest('.nav-item'))
        }

        if ($this.data('toggle')) {
            $this.on('show.bs.tab', function () {
                calcCenter($this.closest('.nav-item'))
            });
        }
    });



    function calcCenter($this) {
        if($this && $this.length) {
            let childPos = $this.offset().left,
                parentScroll = $this.closest('.js-scrolling-tabs').scrollLeft(),
                offset = childPos + parentScroll - window.outerWidth/2 + $this.width()/2;

            try {
                $this.closest('.js-scrolling-tabs')[0].scroll({
                    left: offset,
                    behavior: 'smooth'
                });
            } catch (e) {
                $this.closest('.js-scrolling-tabs').stop().animate({
                    scrollLeft: offset
                }, 200, 'swing');
            }
        }
    }

}
