"use strict";
import {loadParsley} from "@elements/parsley-bootstrap-validation";

export function initInScope ($scope) {
    loadParsley().then(function () {
        window.Parsley.addValidator('fileextension', function (value, requirement) {
            let fileExtension = value.split('.').pop();
            let requirements = requirement.replace(/\s/g, "").split(',');

            return requirements.indexOf(fileExtension) !== -1;
        }, 32);
    });
}