"use strict";

let sliderId = 0;

export function initInScope ($scope) {
    let $slider = $scope.find('.js-img-text-slider');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-img-text-slider__main');
            let $textSlider = $container.find('.js-img-text-slider__text');
            let id = sliderId++;

            $mainSlider.attr('id', 'text-main-slider__main-' + id);
            $textSlider.attr('id', 'text-thumb-slider__thumb-' + id);

            $mainSlider.slick({
                asNavFor: '#text-thumb-slider__thumb-' + id,
                slidesToShow: 1,
                dots: false,
                arrows: false
            });

            if ($textSlider && $textSlider.length) {
                $textSlider.slick({
                    dots: true,
                    slidesToShow: 1,
                    asNavFor: '#text-main-slider__main-' + id,
                    fade: true,
                    dotsClass: 'list-unstyled img-text-slider__nav text-color-dark',
                    customPaging: function(slider, i) {
                        let sliderItem = $(slider.$slides[i]).find('.js-img-text-slider__text__item');
                        return '<button type="button" class="btn-no-styling img-text-slider__nav__item">' + sliderItem.data('title') + '</button>';
                    },
                    prevArrow: '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
                    nextArrow: '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
                    appendArrows: $textSlider.closest('.js-img-text-slider__text-container'),
                    appendDots: $container.find('.js-img-text-slider__text-nav-container'),
                    adaptiveHeight: !matchMedia('(min-width: 768px)').matches
                });
            }
        });
    });
}