import * as gtm from './tracking-src/gtm';
import * as matomo from './tracking-src/matomo';
import * as jentis from './tracking-src/jentis';
import $ from "jquery";
import {getPrefixedDataSet} from "@elements/data-set-utils";

export const initJentisEventTracking = createEventTracking(jentis);
export const initGtmEventTracking = createEventTracking(gtm);
export const initMatomoEventTracking = createEventTracking(matomo);


function createEventTracking({ setup, trackEvent }) {

    return ({
                dataAttributePrefix = 'event-tracking',
                debug = false
            } = {}) => {
        setup({debug});
        // todo repalce with onFind
        $(document.body).on('click', `[data-${dataAttributePrefix}-category]`, function (e) {
            let $this = $(this);
            let clickedElement = this;
            let $clickedElement = $(clickedElement);
            let data = {};

            if (clickedElement.tagName === 'A') {
                data.action = clickedElement.getAttribute('href');
                data.label = trim(clickedElement.textContent);
            }

            data = {
                ...data,
                ...getPrefixedDataSet(dataAttributePrefix, $clickedElement)
            };

            trackEvent(data, {debug});

            // if($this.is('a')) {
            //     setTimeout(() => {
            //         window.location.href = $this.attr('href');
            //     }, 1100);
            // }
        })
    }
}

function trim(str) {
    return str.replace(/\s\s+/g, ' ').trim();
}

export function responseTracking(request, debug = false) {
    // Unpack json response body if the promise was created via fetch
    let promise = request.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    promise.then(result => {
        let responseTrackingData = result.responseTrackingData;
        if (responseTrackingData) {
            try {
                execResponseTracking(responseTrackingData,true);
            } catch (e) {
                console.error(e)
            }
        }
    });
}

export function resultTracking(result, debug = false) {
    let responseTrackingData = result.responseTrackingData;
    if (responseTrackingData) {
        try {
            execResponseTracking(responseTrackingData, debug);
        } catch (e) {
            console.error(e)
        }
    }
}

export function execResponseTracking(responseTrackingData,debug){

    Object.keys(responseTrackingData).forEach(service => {
        console.log(service, responseTrackingData[service]);

        if (service === 'gtm'){
            if (Array.isArray(responseTrackingData[service].datalayer)){
                responseTrackingData[service].datalayer.map((trackingData) => {
                    window.dataLayer.push(trackingData);
                })
            } else {
                window.dataLayer.push(responseTrackingData[service].datalayer);
            }

            if (debug){
                console.log('GTM Tracking - dataLayer push:', responseTrackingData[service].datalayer);
            }

        }else if(service === 'matomo'){
            _paq.push((responseTrackingData[service].datalayer))

            if (debug){
                console.log('Matomo event tracking - _paq.push:', responseTrackingData[service].datalayer);
            }
        } else if(service === 'jentis'){
            _jts.push(responseTrackingData[service].datalayer);

            if (debug) {
                console.log('Jentis Tracking - dataLayer push:', responseTrackingData[service].datalayer);
            }
        }
    });
}

