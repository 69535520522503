"use strict";
export function setup() {
    window._jts = window._jts || [];
}
export function initInScope ($scope) {
    let $shariffItem = $('.js-shariff .shariff-button');
    console.log('hi', $shariffItem);
    $shariffItem.each(function() {
        let $socialLink = $(this);
        $socialLink.attr({
            'data-event-tracking-category': 'social_media_share',
            'data-event-tracking-label': $socialLink.attr('href'),
        });
        let dataLayerData = {
            'track': 'event',
            'event-name': 'genericGtmEvent',
            'genericEventCategory': $this.data('data-event-tracking-category'),
            'genericEventLabel': $this.data('data-event-tracking-label'),
        };

        _jts.push(dataLayerData);
        console.log('Jentis Tracking Form - dataLayer push:', dataLayerData);
    });
}