"use strict";

export function initInScope($scope) {
    console.log('init toggle-overlay')
    $scope.find('.js-overlay-toggle').on('click', function (evt) {
       evt.preventDefault();

       let $target = $($(this).data('target'));
        console.log('target', $target)

       if(!$target.hasClass('is-open')) {
           $target.addClass('is-open');
           $(this).addClass('is-active');

           //set focus to input
           if($target.find('.js-search input[type="text"]')) {
               $target.find('.js-search input[type="text"]').filter(':visible:first').focus();
           }

           //close navbar
           if($(this).closest('.navbar').hasClass('is-open')) {
               $(this).closest('.navbar').removeClass('is-open');
           }

       } else {
           $target.removeClass('is-open');
           $(this).removeClass('is-active');
       }
    });
}