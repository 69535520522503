"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";
import {onEnterViewPort} from "@elements/viewport-utils";
import {
    default as initMap,
    setMarker,
    activateMarker,
    setDefaultMarkerStyle,
    showInfoBoxByMarker
} from "@elements/leaflet-map";
import * as leaflet from "leaflet";
import {GestureHandling} from "leaflet-gesture-handling";

export function initInScope($scope) {
    let $maps = $scope.find('.js-leaflet-map'),
        $container;

    if ($maps && $maps.length) {

        $maps.each(function (index, element) {
            $container = $(element);
            let $mapCanvas = $container.find('.js-leaflet-map__canvas');

            leaflet.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

            const mapLayer = leaflet.tileLayer('' + _config.mapLayerUrl + '/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                maxZoom: 18,
                accessToken: _config.mapAccessToken
            });

            const poiStyles = {
                default: {
                    default: leaflet.icon({
                        iconUrl: '/static/build/img/map/marker-default.svg',
                        iconSize: [20, 20],
                        iconAnchor: [10, 10],
                        popupAnchor: [0, -10],
                    }),
                    active: leaflet.icon({
                        iconUrl: '/static/build/img/map/marker-default.svg',
                        iconSize: [20, 20],
                        iconAnchor: [10, 10],
                        popupAnchor: [0, -10],
                    })
                },
                partner: {
                    default: leaflet.icon({
                        iconUrl: '/static/build/img/map/marker-blue.svg',
                        iconSize: [20, 20],
                        iconAnchor: [10, 10],
                        popupAnchor: [0, -10],
                    }),
                    active: leaflet.icon({
                        iconUrl: '/static/build/img/map/marker-blue.svg',
                        iconSize: [20, 20],
                        iconAnchor: [10, 10],
                        popupAnchor: [0, -10],
                    })
                }
            };

            const infoBoxOptions = {
                maxWidth: matchMedia('(max-width: 767px)').matches ? 320 : 1136,
                minWidth: matchMedia('(max-width: 767px)').matches ? 280 : 890,
                className: 'map-infobox'
            };

            let poiVarName = $container.data('leaflet-map-pois');
            let pois = [];

            if (poiVarName) {
                if (!window['_leafletMapPois']) {
                    console.error('_leafletMapPois is not set');
                } else if (!window._leafletMapPois[poiVarName]) {
                    console.error(poiVarName + ' is not set in _leafletMapPois', _leafletMapPois);
                } else {
                    pois = _leafletMapPois[poiVarName];
                }
            }

            let options = {
                gestureHandling: true
            };

            let mapObj = initMap({
                element: $mapCanvas[0],
                mapOptions: options,
                mapLayer,
                poiStyles,
                infoBoxOptions,
                clustering: false,
                onActivateMarker,
                onDeactivateMarker
            });

            setMarker(mapObj, pois);

            $container.data('map-obj', mapObj);

            if ($container.data('leaflet-map-fix-zoom')) {
                mapObj.map.setZoom($container.data('leaflet-map-fix-zoom'));
            }
        });
    }

    $scope.find('.js-active-marker').on('click', function (evt) {
        evt.preventDefault();
        let markerId = $(this).data('marker-id');

        $('.js-leaflet-map').data('map-obj').marker.filter(function (marker) {
            if (marker.dataId === markerId) {
                activateMarker(marker, $('.js-leaflet-map').data('map-obj'));
            }
        });

        let offset = matchMedia('(max-width: 767px)').matches ? 150 : 100;

        $('html, body').stop().animate({
            scrollTop: $('.js-leaflet-map').offset().top - offset
        }, 250, 'swing');
    });
}

function onActivateMarker(marker, mapObj) {
    // console.log(marker, mapObj)
    // console.log('onActivateMarker');
    if (marker.detailInfoBoxUrl) {
        let infoBoxPromise = showInfoBoxByMarker(
            marker,
            `<div class="my-2">
                <div class="loading-spinner" aria-label="Loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>
            </div>`,
            mapObj
        );

        let contentPromise = $.ajax(marker.detailInfoBoxUrl, {
            cache: true,
            dataType: 'html',
            headers: {Ajax: 1}
        }).catch(function (a, b, c) {
            console.error(a, b, c);
        });

        $.when(infoBoxPromise, contentPromise).then(function (infoBox, [infoBoxContent]) {
            if (infoBox.isOpen()) {
                // open the new info box with the new content only if the old one is still open
                infoBox.setContent(infoBoxContent);
            }

            initModulesInScope($(infoBox._container));

            //set marker default style on popupclose
            marker.on('popupclose', function () {
                setDefaultMarkerStyle(marker);
            });
        });
    }
}

function onDeactivateMarker(marker, mapObj) {
    marker.closePopup().unbindPopup();
}