"use strict";

import {getPrefixedDataSet} from '@elements/data-set-utils';
import {loadParsley} from '@elements/parsley-bootstrap-validation';

export function init() {
    loadParsley().then(function () {
        let $dropdown = $('.js-multiselect-dropdown');
        let $menu = $dropdown.find('.js-multiselect-dropdown__menu');
        let $button = $dropdown.find('.js-multiselect-dropdown__button');
        let $value = $dropdown.find('.js-multiselect-dropdown__value');
        let $checkboxes = $dropdown.find('.js-multiselect-dropdown__checkbox');

        //initial for floating label to work
        $button.trigger('change');

        //show or hide menu
        $button.on('click', function (evt) {
            if (!$menu.hasClass('is-open')) {
                $menu.addClass('is-open');
                $button.attr('aria-expanded', 'true');
                $('body').on('click', bodyClickHandler);
            } else {
                $menu.removeClass('is-open');
                $button.attr('aria-expanded', 'false');
                $('body').off('click', bodyClickHandler);
            }
        });

        //show count and validation
        $checkboxes.on('change', function (evt) {
            let count = 0;
            $value.empty();

            $checkboxes.each(function (i) {
                if ($checkboxes[i].checked === true) {
                    count++;
                }
            });

            $value.append(count + ' ' + getPrefixedDataSet('multiselect-dropdown', $dropdown).selectedTranslation);
            $button.attr('placeholder', 'true');
            $button.trigger('change');

            if($button.hasClass('is-valid') || $button.hasClass('is-invalid')){
                $(this).closest('form').parsley().validate();
            }
        });

        $dropdown.closest('form').parsley().on('form:validated', function(e){
            if($checkboxes.hasClass('is-invalid')){
                $button.addClass('is-invalid');
            }
            else if($checkboxes.hasClass('is-valid')){
                if($button.hasClass('is-invalid')){
                    $button.removeClass('is-invalid');
                    $button.addClass('is-valid');
                }else{
                    $button.addClass('is-valid');
                }
            }
        })
    });
}

function bodyClickHandler (evt) {
    if ($(evt.target).parents('.js-multiselect-dropdown').length < 1) {
        evt.preventDefault();
        $('.js-multiselect-dropdown__menu').removeClass('is-open');
        $('.js-multiselect-dropdown__button').attr('aria-expanded', 'false');
        $('body').off('click', bodyClickHandler);
    }
}