"use strict";
import fetch from '@elements/fetch';
import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope ($scope) {
    let $tabs = $scope.find($('.js-fetch-tabs'));

    $tabs.find('[data-toggle="tab"]').on('show.bs.tab', function (evt) {

        let $target = $($(this).attr('href'));

        if ($target.data('tab-content')) {
            let pendingRequest = fetch($target.data('tab-content'));

            $target.find('.js-fetch-tabs__loading').prop('hidden', false);

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                if (res.success) {
                    $target.html(res.html);
                    initModulesInScope($target);
                }
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    /*Do error stuff*/
                    console.error(error);
                }
            });
        }
    });
}
