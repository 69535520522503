"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-slider'),
        $container = $slider.closest('.js-slider-container');

    import('slick-carousel').then(function () {
        $slider.on('setPosition', function(event, slick){
            $container.find('.js-slider--count-actual').text(slick.currentSlide + 1).slice(-2);
            $container.find('.js-slider--count-sum').text(slick.slideCount).slice(-2);
        });
    });
}
