"use strict";

let defaultOptions = {
    markers: [25,50,75,100] //adjust these values if you want different progress reports
};

let videos = [];

export function initVideoTracking (video, settings = {}) {
    settings = {
        ...defaultOptions,
        ...settings
    };

    let category = $(video.el_).data('tracking-category'),
        label = $(video.el_).data('tracking-label');

    videos.push({
        'id': video.id_,
        'markers': []
    });


    video.on('playing', function () {
        dataLayerPush(category, 'play', label, '');
    });

    video.on('pause', function () {
        dataLayerPush(category, 'pause', label, '');
    });

    video.on('ended', function () {
        dataLayerPush(category, 'end', label, '');
    });

    video.on('timeupdate', function (evt) {
        let percentPlayed = Math.floor(this.currentTime()*100/this.duration());
        let playerMarkerIndex = findObjectIndexById(videos,'id', this.id_);

        if(settings.markers.indexOf(percentPlayed)>-1 && videos[playerMarkerIndex].markers.indexOf(percentPlayed)==-1) {
            videos[playerMarkerIndex].markers.push(percentPlayed);
            dataLayerPush(category,'progress', label, percentPlayed);
        }
    });
}

function findObjectIndexById(haystack, key, needle) {
    for (let i = 0; i < haystack.length; i++) {
        if (haystack[i][key] == needle) {
            return i;
        }
    }
    return null;
}

function dataLayerPush(category, action, label, value) {
    if(window.dataLayer) {
        dataLayer.push({
            'event': 'genericGtmEvent',
            'genericEventCategory': category, //video
            'genericEventAction': action, //state (play,pause,end,progress)
            'genericEventLabel': label, // video name
            'genericEventValue': value //empty
        });
    }
}