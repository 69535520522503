export function setup() {
    window._paq = window['_paq'] || [];
}

export function trackEvent({category, action, label, value}, {debug}) {
    _paq.push(['trackEvent', category, action, label, value]);

    if (debug) {
        console.log('Matomo event tracking - _paq.push:', 'trackEvent', category, action, label, value);
    }
}

function exists(x) {
    return x !== null && typeof x !== 'undefined'
}