"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope() {
   let template = $('.js-modal-template');

   template.each(function(){
       $('body').append($(this).html());
       $(this).remove();
   });

   initModulesInScope();
}