'use strict';

export function initInScope($scope) {
    let $fileContainer = $scope.find('.js-file-uploads'),
        $files = $fileContainer.find('input[type=file]');

    $files.on('change', (e) => {
        getFileSizes()
    });

    function getFileSizes() {
        let totalFileSize = 0;
        $.each($files, function (index, value) {
            if(value.files[0]){
                totalFileSize += value.files[0].size;
            }
        });

        totalFileSize = totalFileSize/1024/1024; /*byte to mb*/
        /*if size bigger than 10 mb*/
        if(totalFileSize > 7){
            $fileContainer.data('file-error');
            $fileContainer.append(`<div class="text-danger js-error-message">${$fileContainer.data('file-error')}</div>`);

            $fileContainer.closest('form').find('button[type=submit]').attr('disabled', 'disabled');
        }else{
            $fileContainer.find('.js-error-message').remove();
            $fileContainer.closest('form').find('button[type=submit]').removeAttr('disabled');
        }
    }
}