"use strict";

export function initInScope($scope) {
    let acceptedDownloadModal = JSON.parse(window.sessionStorage.getItem('acceptedDownloadModal')) || [];

    $scope.find('.js-download-modal').each(function (evt) {
        let $modal = $(this);

        $modal.find('.js-download-modal__agree').on('click', function (evt) {
            evt.preventDefault();

            acceptedDownloadModal.push('#' + $modal.prop('id'));

            window.sessionStorage.setItem('acceptedDownloadModal', JSON.stringify(acceptedDownloadModal));

            $modal.modal('hide').trigger('modal.accepted');
        });
    });

    $scope.find('.js-download-modal--show').on('click', function (evt) {
        let $this = $(this);

        if (window.sessionStorage.getItem('acceptedDownloadModal')) {
            let savedModals = JSON.parse(window.sessionStorage.getItem('acceptedDownloadModal'));

            let accepted = savedModals.filter(function (id) {
                return id === $this.data('target');
            }).length;

            if (!accepted) {
                evt.preventDefault();
                $($this.data('target')).modal('show');
            }

        } else {
            evt.preventDefault();

            $($this.data('target')).modal('show');

            $($this.data('target')).on('modal.accepted', function () {
                window.location.href = $this.attr('href');
            });
        }
    })
}