export function setup() {
    window._jts = window._jts || [];
}

export function init($scope) {
    $(document).ready(function() {
        let pageHeight = $(document).height(),
            scrollPositions = [0.25, 0.5, 0.75, 0.9],
            messagesDisplayed = {};

        $(document).scroll(function() {
            let scrollPosition = $(document).scrollTop();

            for (let i = 0; i < scrollPositions.length; i++) {
                let positionPercentage = scrollPositions[i] * 100;

                if (scrollPosition >= scrollPositions[i] * pageHeight && !messagesDisplayed[positionPercentage]) {
                    let dataLayerData = {
                        'track': 'event',
                        'event-name': 'genericGtmEvent',
                        'genericEventCategory': "scroll",
                        'genericEventAction': "scroll_depth",
                        'genericEventLabel': (scrollPositions[i] * 100) + "%",
                    };
                    _jts.push(dataLayerData);
                    console.log('Jentis Tracking Form - dataLayer push:', dataLayerData);

                    messagesDisplayed[positionPercentage] = true;
                }
            }
        });
    });
}