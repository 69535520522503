"use strict";

export function initInScope ($scope) {
    let $shariff = $scope.find('.js-shariff');

    import('shariff').then(function () {
        new Shariff($shariff,{

        });
    });
}