"use strict";

let $navbar,
    $navbarBar,
    $navbarContainer,
    offset,
    didScroll,
    affixheight,
    defaultTopPosition;

function scrollHandler() {
    if($(window).scrollTop() > offset) {
        if(matchMedia('(min-width: 768px)').matches) {
            $navbarBar.outerHeight(affixheight);
        }
        $navbarContainer.addClass('is-affix');
    } else if($(window).scrollTop() <= offset) {
        $navbarBar.removeAttr('style');
        $navbarContainer.removeClass('is-affix');
    }
}

export function init() {
    $navbar = $('.js-affix-nav-bar');
    $navbarBar = $('.js-affix-nav-bar__bar');
    $navbarContainer = $('.js-affix-nav-bar-container');

    defaultTopPosition = $navbarContainer.offset().top;
    affixheight = $navbarBar.outerHeight() - 180;
    if (affixheight < 290) {
        affixheight = 290;
    }
    console.log(affixheight);

    if(matchMedia('(max-width: 767px)').matches) {
        offset = $navbarContainer.offset().top;
        console.log(offset);

        if($(window).scrollTop() > offset) {
            $navbar.addClass('is-affix');
        }

        $(window).one('scroll', function () {
            $navbarContainer.height($navbar[0].getBoundingClientRect().height); /*get exact height (jquery returns rounded value)*/
        });
    } else {
        offset = $navbarBar.outerHeight() - affixheight;

        if($(window).scrollTop() > offset) {
            $navbarContainer.addClass('is-affix');
        }
    }

    $(window).scroll(function () {
        didScroll = true;
    });

    requestAnimationFrame(function animationFrameHandler() {
        if (didScroll) {
           scrollHandler();

            didScroll = false;
        }
        requestAnimationFrame(animationFrameHandler);
    });
}