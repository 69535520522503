"use strict";

export function initInScope ($scope) {
    if (matchMedia('(min-width: 768px)').matches) {
        return;
    }

    let $navItems = $scope.find('.js-touch-nav__item'),
        $navItemsLinks = $scope.find('.js-touch-nav__item__toggle');


    $navItemsLinks.on('click', function (evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();

        let $item = $(this).closest('.js-touch-nav__item'),
            $navContainer = $(this).closest('.js-touch-nav-container');

        if (!$item.hasClass('is-open')) {
            $navItems.removeClass('is-open');
            $navContainer.addClass('has-subnav-open');

            $item.addClass('is-open');
        } else {
            $navContainer.removeClass('has-subnav-open');
            $item.removeClass('is-open');
        }
    });
}