"use strict";

import 'url-search-params-polyfill'; // Edge Polyfill
import 'whatwg-fetch'; // IE10 Polyfill

export function initInScope ($scope) {
    let pendingRequest;
    let cache = [];

    $scope.find('.js-search__form__input').on('input', throttle(function () {
        let $this = $(this),
            $container = $this.closest('.js-search'),
            $form = $this.closest('form'),
            $target = $container.find('.js-search__result'),
            val = $(this).val();

        if(val.length > 3) {
            if (pendingRequest) {
                pendingRequest.abort();
            }

            $container.find('.js-search__loading').attr('hidden', false).removeClass('hidden');

            let url = $form.data('action') + ($form.data('action').indexOf('?') < 0 ? '?': '&') + $form.serialize();

            let cacheResult = $.grep(cache, function (cacheEntry) { return cacheEntry.url === url});

            if (cacheResult.length) {
                $container.find('.js-search__loading').attr('hidden', true).addClass('hidden');
                $target.empty();
                $target.html(cacheResult[0].content);
            } else {
                pendingRequest = fetch($form.data('action'), {
                    method: 'POST',
                    headers: {
                        'X-Requested-With' : 'XMLHttpRequest',
                        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                    },
                    body: new URLSearchParams($form.serialize())
                }).then((res) => {
                    return res.json()
                }).then((res) => {
                    pendingRequest = null;
                    if (res.success) {
                        $container.find('.js-search__loading').attr('hidden', true).addClass('hidden');
                        $target.empty();
                        $target.html(res.html);

                        cache.push({
                            url: url,
                            content: res.html
                        });

                        if (cache.length > 40) {
                            cache.shift();
                        }
                    }
                }).catch((error) => {
                    console.error("loading data-action failed:" + error);
                });
            }
        }

        if(val.length === 0) {
            $target.empty();
        }
    }, 300));
}

function throttle (fnc, time) {
    let timeout;

    return function () {
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(function () {
            fnc.call(this);
            clearTimeout(timeout);
        }.bind(this), time);
    };
}