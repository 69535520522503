import {loadParsley} from '@elements/parsley-bootstrap-validation';
import {execResponseTracking} from '../tracking';
export function setup() {
    window._jts = window._jts || [];
}

export function initInScope($scope) {
    loadParsley().then(function () {
        let $jentisForm = $scope.find('.js-jentis-form');
        let $jentisFormInput = $scope.find('.js-jentis-form-input');

        $jentisFormInput.each(function(){
            let $this = $(this);
            if($this){
                $this.on('input', function(e){
                    let dataLayerData = {
                        'track': 'event',
                        'event-name': 'genericGtmEvent',
                        'genericEventCategory': $this.data('jentis-form-category-input'),
                    };
                    _jts.push(dataLayerData);
                    console.log('Jentis Tracking Form - dataLayer push:', dataLayerData);
                    $this.off('input');
                });
            }
        });
        $jentisForm.each(function(){
            let $this = $(this);

            if($this){
                let trackingEvent = $this.data('jentis-tracking-event');
                if(trackingEvent == 'submit'){
                    $this.parsley().on('form:validate', function (formInstance) {
                        if (formInstance.isValid()) {
                            let dataLayerData = {
                                'track': 'event',
                                'event-name': 'genericGtmEvent',
                                'genericEventCategory': $this.data('jentis-form-category'),
                            };
                            _jts.push(dataLayerData);

                            console.log('Jentis Tracking Form - dataLayer push:', dataLayerData);
                        } else {
                            console.log('form not valid');
                        }
                    });
                }else if(trackingEvent == 'ajax-form'){
                    let $jentisInputs = $this.find('.js-jentis-form__input');
                    $jentisInputs.each(function(){
                        let $this = $(this);
                        $this.on('change', function() {
                            let dataLayerData = {
                                'track': 'event',
                                "event-name": "genericGtmEvent",
                                'genericEventCategory': "select_filter",
                                'genericEventAction': $this.attr('name'),
                                'genericEventLabel': $this.hasClass('js-jentis-form__input--select') ? $("option:selected", this).text().trim() : $this.val(),
                            };
                            _jts.push(dataLayerData);

                            console.log('Jentis Tracking Form - dataLayer push:', dataLayerData);
                        });
                    });
                    // let baseAjaxForm = $this.closest('.js-ajax-form');
                    // console.log('baseAjaxForm', baseAjaxForm);
                    // baseAjaxForm[0].addEventListener('fetched.ajax-form', function(evt) {
                        // console.log('fetched', evt);
                        // let result = evt.detail;
                        // let responseTrackingData = result.responseTrackingData;
                        // if (responseTrackingData) {
                        //     console.log('responseTrackingData', responseTrackingData);
                        //     try {
                        //         execResponseTracking(responseTrackingData,true);
                        //     } catch (e) {
                        //         console.error(e)
                        //     }
                        // }
                    // });
                }
            }
        })
    });
}

