"use strict";
import loadScript from "@elements/load-script";
import {onEnterViewPort} from "@elements/viewport-utils";

let currentId = 1;
export function initInScope ($scope) {
    let $recaptcha = $scope.find('.js-recaptcha');

    onEnterViewPort($recaptcha, function (element) {

        loadRecaptchaAPI();

        window.onloadCallback = function () {
            $recaptcha.each(function () {
                let $this = $(this),
                    id = $(this).prop('id');

                if (!id) {
                    id = 'recaptcha-id-' + currentId++;
                    $(this).prop('id', id);
                }

                grecaptcha.render($(this)[0], {
                    'sitekey': $(this).data('sitekey'),
                    'callback': reCaptchaVerify
                });

                addValidation($(this).closest('form'), $this);
            });
        };

    }, {
        offset: 500
    });

}

export function loadRecaptchaAPI() {
    return loadScript("https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit");
}

function reCaptchaVerify() {
    console.log('reCaptchaVerify');
}

function addValidation($form, recaptcha) {
    $form.find('[type=submit]').on('click', function (evt) {
        $form.find('.js-recaptcha__input').val('');

        if(grecaptcha.getResponse().length === 0) {
            $form.find('.js-recaptcha__input').val('');
        } else {
            $form.find('.js-recaptcha__input').val(grecaptcha.getResponse());
        }
    });
}