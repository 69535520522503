import initModulesInScope from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";


export function initInScope($scope) {
    const defaultSelectors = {
        base: '.js-consent',
        template: '.js-consent__template',
        dataset: 'consent',
        button: '.js-consent__accept-btn',
    };

    const defaultOptions = {
        cmpTool: 'onetrust',
    }

    let baseElement = $scope.find(defaultSelectors.base);

    if (baseElement) {
        if(defaultOptions.cmpTool === 'onetrust'){
            createOnetrustOverlay(
                baseElement,
                defaultOptions,
                defaultSelectors
            );
        } else{
            console.log('cmpTool has not been defined yet');
        }
        //add more cmpTool specific functions above else statement
    }
}

export function renderTemplate(renderItem, selectors) {
    let template = renderItem.find(selectors.template);

    if(template) {
        if (renderItem.parent()) { // add this check to prevent parent being null
            let parent = renderItem.parent();

            //everything about the overlay will be overwritten
            parent.html(template.html());
            initModulesInScope(parent);
        } else {
            console.error("renderItem does not have a parent element");
        }
    }
}

export function createOnetrustOverlay(baseElement, options, selectors) {
    let data = getPrefixedDataSet(selectors.dataset, baseElement);
    let button = baseElement.find(selectors.button);

    if (OnetrustActiveGroups.includes(data.service)) {
        renderTemplate(baseElement,  {base: selectors.base, template: selectors.template});
    } else {
        if (baseElement.attr('hidden')) {
            baseElement.removeAttr('hidden');
        }
    }


    let onChangeListening = false;
    const originalWrapper = window.OptanonWrapper;

    if (originalWrapper) {
        window.OptanonWrapper = () => {
            originalWrapper();

            if (OneTrust && !onChangeListening) {
                onChangeListening = true;

                OneTrust.OnConsentChanged(() => {
                    if (OnetrustActiveGroups.includes(data.service)) {
                        renderTemplate(baseElement, { base: selectors.base, template: selectors.template });
                    }
                    else {
                        if (baseElement.attr('hidden')) {
                            baseElement.removeAttr('hidden');
                        }
                    }
                });
            }
        };
    }

    if(button){
        button.on('click', () => {
            OneTrust.ToggleInfoDisplay();
        });
    }
}