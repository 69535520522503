"use strict";

export function init () {
    //script for scrolling to element with url param ?scrollTo=elementId
    let searchParams = new URLSearchParams(window.location.search)

    if(searchParams.has('scrollTo')){
        let $target = $('#' + searchParams.get('scrollTo'));

        $(window).on('load', function (evt) {
            evt.preventDefault();

            let tabId = $target.closest('.tab-pane').prop('id');
            let tab = $('a[href="#' + tabId + '"]');
            let collapse = $target.closest('.collapse');

            if(tab.length && collapse.length && !tab.hasClass('active')){
                tab.on('shown.bs.tab', function(){
                    collapse.on('shown.bs.collapse', function(){

                        if (!$target || !$target.length) {
                            console.warn("Can't scroll to '" + $target + "'. Target element not found");
                            return;
                        }

                        let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 100;
                        let offset = $target.offset().top - 100;

                        $('html, body').stop().animate({
                            scrollTop: offset
                        }, duration, 'swing');
                    })
                    collapse.collapse('show');
                });
                tab.tab('show');
            }else if(tab.length && !tab.hasClass('active')){
                tab.on('shown.bs.tab', function(){
                    if (!$target || !$target.length) {
                        console.warn("Can't scroll to '" + $target + "'. Target element not found");
                        return;
                    }

                    let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 100;
                    let offset = $target.offset().top - 100;

                    $('html, body').stop().animate({
                        scrollTop: offset
                    }, duration, 'swing');
                });
                tab.tab('show');
            }else if(collapse.length){
                collapse.on('shown.bs.collapse', function(){

                    if (!$target || !$target.length) {
                        console.warn("Can't scroll to '" + $target + "'. Target element not found");
                        return;
                    }

                    let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 100;
                    let offset = $target.offset().top - 100;

                    $('html, body').stop().animate({
                        scrollTop: offset
                    }, duration, 'swing');
                })
                collapse.collapse('show');
            }else{
                if (!$target || !$target.length) {
                    console.warn("Can't scroll to '" + $target + "'. Target element not found");
                    return;
                }

                let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 100;
                let offset = $target.offset().top - 100;

                $('html, body').stop().animate({
                    scrollTop: offset
                }, duration, 'swing');
            }
        });
    }



}